*
{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.app
{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-color:rgb(171, 163, 255);
}
.overlay
{
    width:100%;
    height: 100vh;
    background-color:rgba(246, 241, 241, 0.268);
}
.container
{
    max-width: 800px;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 1rem;
}
 .section
{
    width: 100%;
    padding:1rem;
    border-radius: 0.4rem;
    color:white;
} 
.section__inputs, .section__temprature
{
    display: flex;
    align-items: center;
    
    justify-content: space-between;
    background-color:rgba(96, 91, 243, 0.923);
    color: rgb(251, 242, 242);
}
.section__inputs>input
{
    border: 0.8px  solid black;
    border-radius: 0.4rem;
    padding:0.5rem;
    font-size: 24px;
    font-weight: 200;
    
}
.section__inputs>input:focus
{
    outline: none;
}
.section__inputs>button
{
    padding:10px 15px;
    border:none;
    border-radius: 0.4rem;
    font-size: 24px;
    font-weight: 500;
    background-color:rgb(250, 245, 245);
}
.section__inputs>button:hover
{
cursor: pointer;
background-color: rgb(243, 171, 171);
}
.icon
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.icon>h3
{
    font-size: 15px;
    font-weight: 200;
    text-transform: capitalize;
}
.temperature>h1{
    font-size: 70px;
}
.time{
    font-size: larger;
    
}