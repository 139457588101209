.section__description{
    display: grid;
   grid-template-columns: repeat(3,1fr);
   gap:2.5rem;
   
}
.card
{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   background-color:rgba(35, 12, 248, 0.521);
   padding: 1rem;
   border-radius: 0.4rem;
   width: 100%;
   color: rgb(239, 233, 233);
}
.description__card-icon
{
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap:5px;
   margin-bottom: 12px;
}
.description__card-icon>small
{
   text-transform: capitalize;
}
@media screen and  (max-width:1024px){
   
   .section__description
   {
       grid-template-columns: 1fr 1fr;
   }
}
